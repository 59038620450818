import React, { useEffect } from "react";

import { FaArrowRight, FaRegClock } from "react-icons/fa6";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
    return (
        <div className="container mx-auto flex flex-col items-center justify-between px-4 lg:px-6 mb-0 z-30">
            <div className="w-11/12 flex flex-col my-12">
                <h1 className="w-full mx-auto text-left text-blue-primary font-normal text-3xl lg:text-4xl mt-6 mb-8 lg:mx-0">
                    Privacy <span className="font-bold">Policy</span>
                </h1>

                <p className="w-full mx-auto text-center text-blue-primary font-bold text-xl lg:mt-0 lg:mx-0 mb-4">
                    Informativa al trattamento dati personali<br />
                    ai sensi del Regolamento UE 679/2016 e del D. Lgs. 196/2003
                </p>

                <p className="text-base font-normal text-black mb-4">
                    Le comunichiamo che{' '}
                    <span className="font-bold text-blue-primary">
                        Titolare del trattamento{' '}
                    </span>
                    dei Suoi dati personali (ovvero la persona fisica o giuridica che stabilisce come e per quali finalità trattare informazioni relative a Lei o ad altre persone fisiche che entrano in contatto con la Struttura) è Villa Iris II S.r.l. con sede legale in piazza Gozzano 1 Torino 10132. Il menzionato Titolare del trattamento può essere contattato ai seguenti recapiti:&nbsp;
                </p>

                <ul className="list-disc list-inside mb-4">
                    <li className="font-normal pl-2">
                        <span style={{ fontWeight: 400 }}>Email: </span>
                        <a href="mailto:privacy@villairis2.srl" className="text-base font-medium text-blue-primary text-right no-underline">
                            privacy@villairis2.srl
                        </a>
                    </li>
                    <li className="font-normal pl-2">
                        <span style={{ fontWeight: 400 }}>PEC: </span>
                        <a href="mailto:villairis2@legalmail.it" className="text-base font-medium text-blue-primary text-right no-underline">
                            villairis2@legalmail.it
                        </a>
                    </li>
                    <li className="font-normal pl-2">
                        <span style={{ fontWeight: 400 }}>n° tel. </span>
                        <a href="tel:0161.84.91.17" className="text-base font-medium text-blue-primary text-right no-underline">
                            0161.84.91.17
                        </a>
                    </li>
                </ul>

                <p className="text-base font-normal text-black mb-4">
                    Le comunichiamo altresì che il Titolare del trattamento ha designato un{' '}
                    <span className="font-bold text-blue-primary">Responsabile per la protezione dei dati{' '}</span>
                    “Data Protection Officer (DPO)” i cui dati di contatto sono i seguenti:
                </p>

                <ul className="list-disc list-inside mb-4">
                    <li className="font-normal pl-2">
                        <span style={{ fontWeight: 400 }}>Email: </span>
                        <a href="mailto:villairis2@dpo-sanita.it" className="text-base font-medium text-blue-primary text-right no-underline">
                            villairis2@dpo-sanita.it
                        </a>
                    </li>
                    <li className="font-normal pl-2">
                        <span style={{ fontWeight: 400 }}>PEC: </span>
                        <a href="mailto:dpo-sanita@legalmail.it" className="text-base font-medium text-blue-primary text-right no-underline">
                            dpo-sanita@legalmail.it
                        </a>
                    </li>
                </ul>


                <div className="space-y-4 mb-2 mt-6">
                    <div className="font-bold text-blue-primary ">INFORMATIVA PRIVACY RSA</div>
                </div>
                <div className="bg-blue-300 hover:bg-blue-500 group block max-w-xs mr-auto px-4 py-2 rounded-3xl mb-6 cursor-pointer transition-all ease-in">
                    <a href="https://kaffeinabucket.s3.eu-west-3.amazonaws.com/villairis/INF-SGDP.02B+INFORMATIVA+PRIVACY+RSA.pdf" target="_blank" className="text-blue-500 group-hover:text-white">
                        Visualizza qui
                    </a>
                </div>

                <div className="space-y-4 mb-2 mt-4">
                    <div className="font-bold text-blue-primary ">INFORMATIVA PRIVACY CONTATTI DESIGNATI OSPITI-DEGENTI</div>
                </div>
                <div className="bg-blue-300 hover:bg-blue-500 group block max-w-xs mr-auto px-4 py-2 rounded-3xl mb-6 cursor-pointer transition-all ease-in">
                    <a href="https://kaffeinabucket.s3.eu-west-3.amazonaws.com/villairis/INF-SGDP.02A_INFORMATIVA_PRIVACY_CONTATTI_DESIGNATI_OSPITI-DEGENTI.pdf" target="_blank" className="text-blue-500 group-hover:text-white">
                        Visualizza qui
                    </a>
                </div>
                
                <div className="space-y-4 mb-2 mt-4">
                    <div className="font-bold text-blue-primary ">INFORMATIVA PRIVACY FORNITORI</div>
                </div>
                <div className="bg-blue-300 hover:bg-blue-500 group block max-w-xs mr-auto px-4 py-2 rounded-3xl mb-6 cursor-pointer transition-all ease-in">
                    <a href="https://kaffeinabucket.s3.eu-west-3.amazonaws.com/villairis/INF-SGDP.03_INFORMATIVA_PRIVACY_FORNITORI.pdf" target="_blank" className="text-blue-500 group-hover:text-white">
                        Visualizza qui
                    </a>
                </div>

                <div className="space-y-4 mb-2 mt-4">
                    <div className="font-bold text-blue-primary ">INFORMATIVA PRIVACY CANDIDATI</div>
                </div>
                <div className="bg-blue-300 hover:bg-blue-500 group block max-w-xs mr-auto px-4 py-2 rounded-3xl mb-6 cursor-pointer transition-all ease-in">
                    <a href="https://kaffeinabucket.s3.eu-west-3.amazonaws.com/villairis/INF-SGDP.01A_INFORMATIVA_PRIVACY_CANDIDATI.pdf" target="_blank" className="text-blue-500 group-hover:text-white">
                        Visualizza qui
                    </a>
                </div>


                {/* <>
                    <p className="text-base font-normal text-black mb-4">
                        Precisiamo inoltre che Ella, in quanto persona fisica cui si riferiscono i dati personali, dunque soggetto ‘
                        <span className="font-bold text-blue-primary">Interessato</span>
                        ’ al trattamento, ha diritto di ricevere una serie di informazioni (contenute nella presente informativa) sul trattamento dei dati e sui correlativi diritti ed obblighi ad Ella riferibili.
                    </p>

                    <p className="text-base font-normal text-black mb-4">
                        <span>
                            La normativa in materia di Privacy (Regolamento UE 2016/679) -la cui osservanza è per Villa Iris II S.r.l. (di seguito anche ‘Struttura’) aspetto prioritario e qualificante- impone di fornirLe preliminarmente alcune informazioni riguardanti il trattamento dei Suoi dati personali comuni e particolari (per tali ultimi intendendosi i dati idonei a rivelare l’origine razziale o etnica, le convinzioni religiose o filosofiche, nonché dati genetici, dati biometrici intesi a indentificare in modo univoco una persona fisica, dati relativi alla salute o alla vita sessuale o all’orientamento sessuale della persona, dati relativi a condanne penali e reati) con riferimento all’attività che verrà esercitata (o potrà essere esercitata) all’interno della Struttura relativamente al servizio di prevenzione, cura, diagnosi e/o riabilitazione che Ella intendesse richiedere.
                        </span>
                    </p>

                    <p className="text-base font-normal text-black mb-4">
                        Quanto alla{' '}
                        <span className="font-bold text-blue-primary">Base giuridica per il trattamento dei dati</span>{' '}
                        si rende noto che il trattamento effettuato per finalità di tutela della salute e dell’incolumità fisica dell’interessato o di terzi o della collettività è effettuato ai sensi dell’art. 6, primo comma, lett. a), e b) (trattamento effettuato previo consenso dell’interessato ovvero per l’esecuzione di un contratto di cui l’interessato è parte) nonché dell’art. 9, comma secondo, lettera h) e comma terzo del Regolamento UE 2016/679 (trattamento di dati particolari senza consenso per finalità di medicina preventiva, diagnosi, assistenza o terapia sanitaria o conformemente al contratto con un professionista della sanità) nonché dell’articolo 2 –
                        <i>septies{' '}</i>
                        del D. Lgs. n. 101/2018.
                    </p>

                    <p className="text-base font-normal text-black mb-4">
                        Ciò premesso intendiamo precisare preliminarmente e con chiarezza le {' '}
                        <span className="font-bold text-blue-primary">Finalità del trattamento</span>{' '}
                        dei dati personali ad Ella riferibili. In particolare i Suoi dati personali vengono raccolti e trattati dal Titolare onde erogare a Suo favore le prestazioni sanitarie e assistenziali per finalità di tutela della Sua salute e/o dell’incolumità fisica e, pertanto, per svolgere attività di diagnosi, prevenzione, cura, riabilitazione, assistenza o terapia sanitaria. I dati personali da Ella forniti al momento del ricovero -nonché quelli raccolti nel corso della Sua degenza presso la Struttura- verranno inoltre trattati per la compilazione della cartella clinica.
                    </p>

                    <p className="text-base font-normal text-black mb-4">
                        <span>
                            I dati personali ad Ella riferibili potranno, inoltre, essere utilizzati in funzione degli adempimenti amministrativo- contabili correlati alle predette attività di cura, diagnosi, prevenzione assistenza o terapia ed altre attività connesse agli adempimenti richiesti anche dal Servizio sanitario nazionale (es. registrazione esenzioni) oltre alle consuete attività di accettazione, prenotazione visite ed esami, refertazione, etc.
                        </span>
                    </p>

                    <p className="text-base font-normal text-black mb-4">
                        <span>
                            I dati personali ad Ella riferibili potranno, infine, essere trattati per ragioni di tutela in giudizio della Struttura e/o del personale nella stessa operante nonché per adempiere ad obblighi di legge nazionale o comunitaria (così in relazione ad adempimenti fiscali, verifiche di carattere ispettivo da parte di Organi di Vigilanza in materia sanitaria, investigazioni di polizia giudiziaria, etc.).
                        </span>
                    </p>

                    <p className="text-base font-normal text-black mb-4">
                        <span>
                            Potranno inoltre eventualmente essere trattati dati sanitari riguardanti Suoi familiari nella misura in cui gli stessi vengano ritenuti indispensabili a fini clinici dal professionista sanitario responsabile delle Sue cure.
                        </span>
                    </p>

                    <p className="text-base font-normal text-black mb-4">
                        Quanto alle {' '}
                        <span className="font-bold text-blue-primary">Modalità del trattamento</span>{' '}
                        La informiamo che i predetti dati personali verranno conservati negli archivi cartacei ed elettronici della Struttura (in modo tale da garantire la sicurezza, riservatezza, integrità e disponibilità dei dati) e trattati (con o senza l’ausilio di strumenti elettronici) con logiche strettamente correlate alle Finalità del trattamento sopra menzionate. La Struttura ha adottato gli opportuni accorgimenti atti a consentire l’accesso e/o l’utilizzo del dato ai soli operatori autorizzati e che ne hanno la necessità in funzione della qualifica posseduta. Tutte le fasi in cui si articola il trattamento dei dati personali verranno curate esclusivamente da personale specificamente incaricato ed istruito dal Titolare o da suo delegato e risulteranno presidiate dal segreto professionale o dal segreto d’ufficio.
                    </p>

                    <p className="text-base font-normal text-black mb-4">
                        Quanto al {' '}
                        <span className="font-bold text-blue-primary">Periodo di conservazione dei dati personali</span>{' '}
                        rendiamo noto che i dati personali ad Ella relativi verranno conservati esclusivamente per il tempo necessario al perseguimento delle finalità per cui sono stati raccolti e trattati, fatto salvo il maggior tempo necessario per adempiere ad obblighi di conservazione cui il Titolare è tenuto in ragione della natura del dato o del documento o in esecuzione di specifici obblighi di legge.
                    </p>

                    <p className="text-base font-normal text-black mb-4">
                        <span>
                            Si rende noto a questo proposito che le cartelle cliniche sono per legge soggette a conservazione senza limite di tempo
                        </span>
                    </p>

                    <p className="text-base font-normal text-black mb-4">
                        Quanto alle c
                        ategorie di {' '}
                        <span className="font-bold text-blue-primary">Soggetti ai quali i Suoi dati personali</span>{' '}
                        [ovvero -di volta in volta- dati anagrafici, terapie, cure da Ella praticate, diagnosi di dimissione con relativa scheda di dimissione e quant’altro necessario]
                        {' '}
                        <span className="font-bold text-blue-primary">saranno comunicati</span>
                        , in base alle norme vigenti (per quanto di competenza e a seconda dei casi), possono indicarsi:
                    </p>


                    <ul className="list-disc list-inside mb-4">
                        <li className="font-normal pl-2">
                            <span>le aziende ospedaliere locali;</span>
                        </li>
                        <li className="font-normal pl-2">
                            <span>le autorità di pubblica sicurezza;</span>
                        </li>
                        <li className="font-normal pl-2">
                            <span>dietro specifica richiesta, l’autorità giudiziaria, Autorità di Vigilanza o ad altri enti destinatari per legge o regolamenti.</span>
                        </li>
                        <li className="font-normal pl-2">
                            <span>in base al contenuto del Regolamento UE n. 679 del 2016 [art. 9 comma secondo lett. j) e 89 comma 1] e del D. Lgs. n. 196/2003 (art. 110 e 110 bis) qualora i Suoi dati dovessero necessitare per finalità di ricerca scientifica o di statistica, saranno trattati in ossequio a tali disposizioni normative;</span>
                        </li>
                        <li className="font-normal pl-2">
                            <span>Compagnia assicurativa della Struttura al fine di tutelare gli operatori e l’Azienda nelle ipotesi di responsabilità professionale.</span>
                        </li>
                    </ul>

                    <p className="text-base font-normal text-black mb-4">
                        I dati personali idonei a rilevare il Suo stato di salute Le saranno notificati dal Direttore Sanitario della struttura e
                        {' '}
                        <span className="font-bold text-blue-primary"> Responsabile del trattamento</span>{' '}
                        dei dati personali il dott. Guadagnuolo Luigi o dalla dott.ssa Frieri Tiziana.
                    </p>

                    <p className="text-base font-normal text-black mb-4">
                        Quanto alla {' '}
                        <span className="font-bold text-blue-primary">Diffusione dei dati</span>{' '}
                        ed il loro eventuale {' '}
                        <span className="font-bold text-blue-primary">Trasferimento in Paesi Extra UE,</span>{' '}
                        Le comunichiamo che i dati personali ad Ella riferibili non sono soggetti a diffusione. Le rendiamo noto che i suoi dati non verranno trasferiti verso paesi Terzi.
                    </p>

                    <p className="text-base font-normal text-black mb-4">
                        Le comunichiamo infine che i {' '}
                        <span className="font-bold text-blue-primary">diritti</span>{' '}
                        ad Ella riconosciuti dalla legge in relazione ai Suoi dati personali sono quelli di cui agli articoli da 14 a 21 del Regolamento UE n. 679 del 27 aprile 2016 ovvero il diritto di accedere ai Suoi dati personali e alle informazioni relative agli stessi; il diritto di chiedere l’aggiornamento, la rettifica o l’integrazione dei dati; il diritto di richiedere la cancellazione dei dati personali alle condizioni di legge; il diritto di richiedere la limitazione del trattamento nei casi previsti dalla legge; il diritto di richiedere la trasmissione dei Suoi dati ad altro Titolare in formato leggibile (cd. diritto alla portabilità); il diritto di opporsi al trattamento qualora ricorrano determinate condizioni di legge; il diritto di revocare il consenso in qualsiasi momento limitatamente alle ipotesi in cui il trattamento sia basato sul Suo consenso (in altre parole qualora il trattamento dei Suoi dati personali avvenga in forza del consenso al trattamento da Ella rilasciato, Le è riconosciuto il diritto di revocare il consenso in qualsiasi momento senza peraltro pregiudicare la liceità del trattamento basata sul consenso prestato prima della revoca); il diritto di presentare reclamo all’Autorità garante per la protezione dei dati personali -i cui recapiti sono rinvenibili sul sito {' '}
                        <a href="http://www.garanteprivacy.it" className="text-base font-medium text-blue-primary text-right no-underline">
                            www.garanteprivacy.it
                        </a>{' '}
                        – in caso di trattamento illecito o di ritardo nella risposta del Titolare ad una richiesta riguardante i Suoi diritti].
                    </p>

                    <p className="text-base font-normal text-black mb-4">
                        Il testo completo dei menzionati articoli del Regolamento UE 2016/679 è a Sua disposizione presso la bacheca posta in prossimità degli uffici amministrativi e sul sito del Garante.
                    </p>

                    <p className="text-base font-normal text-black mb-4">
                        La richiesta per l’esercizio dei diritti di cui sopra deve essere presentata per iscritto ed indirizzata al Titolare del trattamento.
                    </p>

                    <p className="text-base font-normal text-black mb-4">
                        Segnaliamo inoltre che il Regolamento Privacy UE n. 679/2016, per quanto riguarda i dati personali relativi -tra l’altro- alla salute o alla vita sessuale od orientamento sessuale della persona, consente il relativo trattamento in ambito sanitario se necessario per la diagnosi, l’assistenza o terapia sanitaria o sociale sempreché il trattamento avvenga da parte (o sotto la responsabilità) di un professionista soggetto al segreto professionale. Entro i descritti limiti il trattamento dei dati personali relativi alla salute può avvenire senza il consenso dell’interessato [art. 9, comma secondo, lett. h) del Regolamento UE n. 679/2016. E’ fatto salvo -peraltro- il diritto del Singolo Stato membro dell’Unione Europea di mantenere o introdurre ulteriori condizioni per il trattamento dei dati personali genetici, biometrici o relativi alla salute. Il nostro legislatore –avvalendosi di tale facoltà- ha previsto all’art. 2 {' '}
                        <i>
                            septies
                        </i>{' '}
                        del Codice Privacy (D. Lgs. n. 196 del 2003 come modificato per effetto del D. Lgs. n. 101 del 2018) l’introduzione di ulteriori misure di garanzia che, per i dati genetici, possono consistere in prospettiva futura nella reintroduzione del consenso dell’interessato quale condizione legittimante il trattamento.&nbsp;
                    </p>
                </> */}


            </div>
        </div>
    )
}

export default PrivacyPolicy;